<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page

========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert color="danger" title="Simulation Not Found" v-if="errorOccurred">
      <span>Simulation record with id: not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'admin-list-simulation' }" class="text-inherit underline">All Simulation</router-link>
      </span>
    </vs-alert>
    <div id="simulation-data" v-if="simulationData">
      <!--- TOP SIM BANNER START --->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div
            class="rounded-lg w-full main-border"
            style="
              background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
              box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
              background-size: cover;
            "
          >
            <div class="knowledge-base-jumbotron-content rounded-lg p-4" style="backdrop-filter: blur(10px); height: 100%">
              <div class="flex justify-between items-center">
                <div class="lg:p-12 sm:p-10 p-8">
                  <h5 class="font-light text-white ml-1">Simulation Admin</h5>
                  <h1 class="text-white font-bold" style="font-size: 4rem">{{ simulationData.name }}</h1>
                </div>
                <img v-if="simulationData.thumbnail" :src="simulationData.thumbnail" class="rounded-lg mr-4" width="150px" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- TOP SIM BANNER END --->

      <!-- Avatar -->
      <div class="vx-row">
        <div class="vx-col lg:w-2/3 w-full">
          <div class="vx-row mb-4 match-height">
            <div class="vx-col w-1/3">
              <vx-card class="w-full bg-dark flex items-center">
                <h3 class="font-bold">Simulation Id</h3>
                <h3 class="font-light">{{ simulationData.id }}</h3>
              </vx-card>
            </div>
            <div class="vx-col w-1/3">
              <vx-card class="w-full bg-dark flex items-center">
                <h3 class="font-bold">Simulation Type</h3>
                <h3 class="font-light capitalize">{{ simulationData.type }}</h3>
              </vx-card>
            </div>
            <div class="vx-col w-1/3">
              <vx-card class="w-full bg-dark flex items-center">
                <h3 class="font-bold">Simulation Status</h3>
                <h3 class="font-light capitalize">{{ simulationData.status }}</h3>
              </vx-card>
            </div>
          </div>
          <!-- Textarea Course Description -->
          <vx-card title="Simulation Instructions" class="mb-base bg-dark">
            <quill-editor v-if="simulationData" v-model="simulationData.instructions"></quill-editor>
            <div class="flex justify-end items-end mt-4">
              <vs-button icon-pack="feather" icon="icon-edit" @click="updateSimulation">Update Instructions</vs-button>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/3 w-full block">
          <!-- Textarea Course Description -->
          <vx-card title="Actions" class="mb-4 bg-dark">
            <div class="vx-col w-full flex gap-4" id="account-manage-buttons">
              <vs-button icon-pack="feather" icon="icon-edit" class="w-full" @click="editSimulationPopup = !editSimulationPopup"
                >Edit
              </vs-button>
              <vs-button
                color="success"
                icon-pack="feather"
                icon="icon-play"
                class="w-full"
                :to="`/simulations/${$route.params.simulationId}/play`"
                >Play
              </vs-button>
              <!--- <vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash"
                         @click="confirmDeleteRecord">Archive
              </vs-button> --->
            </div> </vx-card
          ><vx-card title="Description" class="mb-4 bg-dark">
            <vs-textarea v-model="simulationData.description" height="230px" disabled />
          </vx-card>
          <vx-card title="Details" class="bg-dark">
            <span class="font-bold mr-1">File Path</span>-<span class="font-light ml-1">{{ simulationData.filePath }}</span></vx-card
          >
        </div>
      </div>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>

    <!-- Edit Popup -->
    <vs-popup :active.sync="editSimulationPopup" title="Edit Simulation">
      <div class="popup-input" v-if="simulationData">
        <p class="mb-1 font-semibold">Name</p>
        <vs-input v-model="simulationData.name" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Description</p>
        <vs-textarea v-model="simulationData.description" height="100px" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Thumbnail</p>
        <vs-input v-model="simulationData.thumbnail" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">File Path</p>
        <vs-input v-model="simulationData.filePath" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Total Score</p>
        <vs-input v-model="simulationData.totalScore" class="mb-3 w-full" />

        <p class="mb-1 font-semibold">Type</p>
        <div class="flex items-center mb-5">
          <vs-radio v-model="simulationData.type" vs-name="simulationType" vs-value="standard" class="mr-4">Standard </vs-radio>
          <vs-radio v-model="simulationData.type" vs-name="simulationType" vs-value="exercises" class="mr-4">Exercise </vs-radio>
        </div>

        <p class="mb-1 font-semibold">Status</p>
        <div class="flex items-center mb-3">
          <vs-radio v-model="simulationData.status" vs-name="simulationStatus" vs-value="active" class="mr-4">Active </vs-radio>
          <vs-radio v-model="simulationData.status" vs-name="simulationStatus" vs-value="disabled" class="mr-4">Disabled </vs-radio>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import 'quill/dist/quill.snow.css';
import VxTimeline from '@/components/timeline/VxTimeline.vue';

import { quillEditor } from 'vue-quill-editor';

export default {
  data() {
    return {
      simulationId: null,
      simulationData: null,
      editSimulationPopup: false,

      errorOccurred: false,
    };
  },
  components: {
    VxTimeline,
    quillEditor,
  },
  methods: {
    fetchSimulationDetails(id) {
      this.$http
        .get(`simulation/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.simulationData = response.data;
          }
        })
        .catch((error) => {
          this.errorOccurred = true;
          this.error_msg = error;
        });
    },
    updateSimulation() {
      this.$http
        .patch(`simulation/${this.simulationId}`, this.simulationData)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.notify({
              title: 'Simulation Updated Successfully',
              text: `${this.simulationData.name} has been updated.`,
              color: 'success',
              position: 'bottom-right',
              iconPack: 'feather',
              icon: 'icon-check-circle',
            });
          }
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while updating this simulation';
          if (exception.response) {
            error = exception.response.data.message;
          }
          this.$vs.notify({
            title: 'Failed To Update Simulation',
            text: error,
            color: 'danger',
            position: 'bottom-right',
            iconPack: 'feather',
            icon: 'icon-x-circle',
          });
        });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Archive',
        text: 'Please confirm you want to archive this simulation.',
        accept: this.deleteRecord,
        acceptText: 'Archive',
      });
    },
    deleteRecord() {
      this.$vs.notify({
        color: 'danger',
        title: "Can't archive simulation",
        text: "At the moment simulations can't be archived. Contact +1-647-244-0868 or cameron@voriant.com",
        position: 'top-right',
      });
    },
  },
  mounted() {
    const id = this.$route.params.simulationId;
    this.simulationId = id;
    this.fetchSimulationDetails(this.simulationId);
  },
  watch: {
    editSimulationPopup(val) {
      if (!val) this.updateSimulation();
    },
  },
};
</script>

<style lang="scss">
.ql-editor {
  min-height: 310px;
}

#avatar-col {
  width: 15rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #accounts-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px), only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #accounts-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }
}
</style>
